import AboutTwo from "../Components/About/AboutTwo";
// import ServicesTwo from "../Components/Services/ServicesTwo";
// import CtaOne from "../Components/Cta/CtaOne";
// import BrandCarouselTwo from "../Components/Brands/BrandCarouselTwo";
import ProjectsTab from "../Components/Projects/ProjectsTab";
// import FooterTwo from "../Components/Footer/FooterTwo";
// import BlogTwo from "../Components/Blog/BlogTwo";
// import TeamTwo from "../Components/Team/TeamTwo";
import Innovation from "../Components/About/Innovation";
import FunFact from "../Components/FunFact/FunFact";
// import PortfolioTwo from "../Components/Portfolio/PortfolioTwo";
// import NavBar from "../Components/Header/Header";
// import TopSection from "../Components/Header/HeaderTwoTop";
import HeroTwo from "../Components/Hero/HeroTwo";
import ProjectsTapHead from "../Components/Projects/ProjectsTabHead";
// import BrandsHeading from "../Components/Brands/BrandsHeading";
import Sustainability from "../Components/Sustainability/main";
import { Helmet } from "react-helmet";

const HomeTwo = () => {
  return (
    <>
      <Helmet>
        <title>Prem Industries India Limited</title>
        <meta name="description" content="Prem Industries India Limited" />
      </Helmet>
      {/* <header className="header-wrap header-2">
        <TopSection />
      </header>
      <NavBar /> */}
      <HeroTwo />
      <AboutTwo
        subhead="About"
        head="Prem Industries India Limited"
        text1="Goel Group's foundation was laid in 1975 by the Chairman Mr. Ved Prakash Goel. Goel Group is now a diversified organization in India with focus on B2B sector. It has created a big footprint in northern India with a portfolio comprising from packaging, steel, retail and construction as the main drivers of growth. Headquartered in Uttar Pradesh, India, it has positioned itself to be the North Indian market leader in packaging and steel trading businesses focusing on large scale development in the region with O&M practices benchmarked to global standards."
        btnClass="theme-btn mt-35"
        btnText="read more"
        verticalText="ABOUT"
      />
      {/* <ServicesTwo /> */}
      {/* <CtaOne /> */}
      {/* <TeamTwo /> */}
      <ProjectsTapHead />
      <ProjectsTab />
      <Sustainability />
      <Innovation />
      {/* <PortfolioTwo /> */}
      {/* <BrandsHeading /> */}
      {/* <BrandCarouselTwo /> */}
      <FunFact />
      {/* <BlogTwo /> */}
      {/* <FooterTwo /> */}
    </>
  );
};

export default HomeTwo;
