import React from "react";

export default function ValueHead() {
  return (
    <>
      <div
        className="container-fluid pt-4 pb-5"
        style={{ backgroundColor: "#14254C" }}
      >
        <div className="row">
          {/* <div className="col-md-12 text-center text-white">
            <h1>Our Values</h1>
          </div> */}
        </div>
      </div>
    </>
  );
}
