// import { Link } from "react-router-dom";
import Card from "./Card";

const ClientPageContents = (props) => {
  return (
    <div className="contact-us-wrapper section-padding">
      <div className="container">
        <div className="row eq-height">
          <div className="col-lg-12 col-12">
            <div className="contact-form text-center shadow-sm">
              <h2>{props.maintitle}</h2>
              {/* <form
                action="mail.php"
                method="POST"
                className="row"
                id="contact-form"
              >
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="text" name="name" placeholder="Name" />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="email" name="email" placeholder="Email" />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="text" name="phone" placeholder="Number" />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="text" name="subject" placeholder="Subject" />
                  </div>
                </div>
                <div className="col-md-12 col-12">
                  <div className="single-personal-info">
                    <textarea
                      name="message"
                      placeholder="message"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-md-12 col-12">
                  <input
                    className="submit-btn"
                    type="submit"
                    defaultValue="Submit Now"
                  />
                </div>
              </form> */}
              <div className="row">
                <div className="col-md-6">
                  <Card
                    CardTitle={props.CardTitle1}
                    CardImage={props.CardImage1}
                    CardLink={props.CardLink1}
                  />
                </div>
                <div className="col-md-6">
                  <Card
                    CardTitle={props.CardTitle2}
                    CardImage={props.CardImage2}
                    CardLink={props.CardLink2}
                  />
                </div>
                <div className="col-md-6">
                  <Card
                    CardTitle={props.CardTitle3}
                    CardImage={props.CardImage3}
                    CardLink={props.CardLink3}
                  />
                </div>
                <div className="col-md-6">
                  <Card
                    CardTitle={props.CardTitle4}
                    CardImage={props.CardImage4}
                    CardLink={props.CardLink4}
                  />
                </div>
              </div>
              <span className="form-message" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientPageContents;
