// import { Link } from "react-router-dom";
import Card from "./Card";

const ContactPageContents = (props) => {
  return (
    <div className="contact-us-wrapper section-padding">
      <div className="container">
        <div className="row eq-height">
          <div className="col-lg-8 col-12">
            <div className="contact-form text-center shadow-sm">
              <h2>{props.maintitle}</h2>
              {/* <form
                action="mail.php"
                method="POST"
                className="row"
                id="contact-form"
              >
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="text" name="name" placeholder="Name" />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="email" name="email" placeholder="Email" />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="text" name="phone" placeholder="Number" />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="text" name="subject" placeholder="Subject" />
                  </div>
                </div>
                <div className="col-md-12 col-12">
                  <div className="single-personal-info">
                    <textarea
                      name="message"
                      placeholder="message"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-md-12 col-12">
                  <input
                    className="submit-btn"
                    type="submit"
                    defaultValue="Submit Now"
                  />
                </div>
              </form> */}
              <div className="row">
                <div className="col-md-6">
                  <Card
                    CardTitle={props.CardTitle1}
                    CardImage={props.CardImage1}
                    CardLink={props.CardLink1}
                  />
                </div>
                <div className="col-md-6">
                  <Card
                    CardTitle={props.CardTitle2}
                    CardImage={props.CardImage2}
                    CardLink={props.CardLink2}
                  />
                </div>
                <div className="col-md-6">
                  <Card
                    CardTitle={props.CardTitle3}
                    CardImage={props.CardImage3}
                    CardLink={props.CardLink3}
                  />
                </div>
                <div className="col-md-6">
                  <Card
                    CardTitle={props.CardTitle4}
                    CardImage={props.CardImage4}
                    CardLink={props.CardLink4}
                  />
                </div>
              </div>
              <span className="form-message" />
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="contact-us-sidebar mt-5 mt-lg-0 shadow-sm">
              <div className="contact-info">
                <h2>CONTACT INFO</h2>
                <div className="single-info">
                  <div className="icon">
                    <i className="flaticon-email" />
                  </div>
                  <div className="text">
                    <span>Email Us</span>
                    <h5 style={{ textTransform: "lowercase" }}>
                      {props.email}
                    </h5>
                  </div>
                </div>
                <div className="single-info">
                  <div className="icon">
                    <i className="flaticon-phone-call-1" />
                  </div>
                  <div className="text">
                    <span>Call Us</span>
                    <h5>{props.number}</h5>
                  </div>
                </div>
                <div className="single-info">
                  <div className="text">
                    <h5>{props.address}</h5>
                  </div>
                </div>
              </div>
              {/* <div className="live-chat">
                <h2>Live Chat</h2>
                <p>You can contact us on chat and explain your problems</p>
                <Link to="/about">
                  <i className="flaticon-chatting" /> Live Chat to Executive
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPageContents;
