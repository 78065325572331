import React from "react";
// import { Link } from "react-router-dom";
// import aboutImg from "../../assets/img/home2/about-cover.jpg";
import SustainabilityImg from "../Images/Sustainability.jpg";

export default function main() {
  return (
    <>
      <section className="about-section section-padding wow fadeInLeft animated">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 mt-5 mt-lg-0 col-12">
              <div className="block-contents">
                <div className="section-title">
                  {/* <span style={{ fontSize: "17px" }}>
                    Sustainability Sub head
                  </span> */}
                  <h2>Sustainability</h2>
                </div>
                {/* <blockquote>Innovation in Action</blockquote> */}
              </div>

              <p style={{ textAlign: "justify", fontSize: "17px" }}>
                As we work towards creating the best customer experience, we
                also Ensure that sustainable alternatives are offered. We are a
                tailored company with mindful efforts toward decreasing our
                carbon footprint. At Prem Industries, we constantly innovate
                towards sustainability, building a host of eco-friendly products
                made from recycled materials. We advocate responsible
                functioning, which ensures that the footsteps of sustainable
                development are followed thoughtfully.
              </p>

              {/* <Link to="/about" className="theme-btn mt-35">
                read more
              </Link> */}
            </div>
            <div className="col-xl-6 col-12 pe-xl-0">
              <div
                className="about-cover-bg bg-cover me-xl-5 shadow-lg"
                style={{ backgroundImage: `url(${SustainabilityImg})` }}
              >
                {/* <div className="our-experience-years shadow-lg">
                  <div className="year-outline">
                    <h2>48</h2>
                  </div>
                  <p>
                    Years of <span>Experience</span>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <h2 className="section-rotate-title-sustainability d-none d-xxl-block">
          GREEN
        </h2>
      </section>
    </>
  );
}
