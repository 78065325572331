import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Preloader from "./Components/Preloader";
import ScrollTopBtn from "./Components/ScrollTopBtn";
import AboutPage from "./pages/AboutPage";
// import ServicePage from "./pages/ServicePage";
// import HomeOne from "./pages/HomeOne";
import LandingPage from "./pages/LandingPage";
// import News from "./pages/News";
// import NewsDetails from "./pages/NewsDetails";
// import TeamPage from "./pages/TeamPage";
// import FaqPage from "./pages/FaqPage";
// import PricingPage from "./pages/PricingPage";
// import ServiceDetails from "./pages/ServiceDetails";
// import HomeThree from "./pages/HomeThree";
// import HomeFour from "./pages/HomeFour";
// import ProjectPage from "./pages/ProjectPage";
import ContactPage from "./pages/ContactPage";
import NotFoundPage from "./pages/NotFound";

// import TopHead from "./Components/Header/HeaderTwoTop";
import NavBar from "./Components/Header/Header";
import Footer from "./Components/Footer/FooterTwo";
import ClientPage from "./pages/ClientPage";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { Helmet } from "react-helmet";

// import TopLoader from "nextjs-toploader";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return <Preloader />;
  } else {
    return (
      <>
        <Helmet>
          <title>Prem Industries India Limited</title>
          <meta name="description" content="Prem Industries India Limited" />
        </Helmet>
        <ScrollTopBtn />
        {/* <header className="header-wrap header-2">
          <TopHead />
        </header> */}
        {/* <TopLoader color="#E92227" showSpinner={false} /> */}
        <NavBar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          {/* <Route path="/homeTwo" element={<HomeOne />} /> */}
          {/* <Route path="/homeThree" element={<HomeThree />} /> */}
          {/* <Route path="/homeFour" element={<HomeFour />} /> */}
          <Route path="/about" element={<AboutPage />} />
          <Route path="/clients" element={<ClientPage />} />
          {/* <Route path="/services" element={<ServicePage />} /> */}
          {/* <Route path="/team" element={<TeamPage />} /> */}
          {/* <Route path="/faq" element={<FaqPage />} /> */}
          <Route path="/contact" element={<ContactPage />} />
          {/* <Route path="/pricing" element={<PricingPage />} /> */}
          {/* <Route path="/newsDetails" element={<NewsDetails />} /> */}
          {/* <Route path="/servicesDetails" element={<ServiceDetails />} /> */}
          {/* <Route path="/news" element={<News />} /> */}
          {/* <Route path="/projects" element={<ProjectPage />} /> */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </>
    );
  }
}

export default App;
