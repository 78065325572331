import React from "react";
import { Link } from "react-router-dom";
// import img1 from "../../assets/img/project-tab.jpg";
// import img2 from "../../assets/img/project-tab2.jpg";
// import img3 from "../../assets/img/project-tab2.jpg";

import PackagingSectorImg from "../Images/PackagingSector.jpg";
import RetailSectorImg from "../Images/RetailSector.jpg";
import SteelSectorImg from "../Images/SteelSector.jpg";
import ConstructorSectorImg from "../Images/ConstructionSector.jpg";

const ScrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

function ProjectsTab() {
  return (
    <section className="our-info-tabs-wrapper wow fadeInLeft animated">
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-12">
            <div className="project-tabs-wrapper">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-tab1"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-tab-1"
                    type="button"
                    role="tab"
                    aria-controls="pills-tab-1"
                    aria-selected="true"
                  >
                    Packaging Sector
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-tab2"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-tab-2"
                    type="button"
                    role="tab"
                    aria-controls="pills-tab-2"
                    aria-selected="false"
                  >
                    Steel Sector
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-tab3"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-tab-3"
                    type="button"
                    role="tab"
                    aria-controls="pills-tab-3"
                    aria-selected="false"
                  >
                    Retail Sector
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-tab4"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-tab-4"
                    type="button"
                    role="tab"
                    aria-controls="pills-tab-4"
                    aria-selected="false"
                  >
                    Construction Sector
                  </button>
                </li>
              </ul>

              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-tab-1"
                  role="tabpanel"
                  aria-labelledby="pills-tab-1"
                >
                  <div className="tab-content-wrapper">
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <h2>
                          Protecting your products with innovative solutions.
                        </h2>
                        <p style={{ textAlign: "justify" }}>
                          For decades, we've been a trusted name in the
                          packaging sector, specializing in high-quality
                          solutions that cater to diverse needs. Our offerings
                          include corrugated boxes, tapes, labels, rigid boxes,
                          flexibles, and pouches. Committed to excellence, we
                          ensure that your products are safely and attractively
                          packaged every time.
                        </p>
                        {/* <div className="data-flex">
                          <div className="single-data">
                            <p>POF</p>
                            <span>Elemental</span>
                            <h6>
                              24.658 <span>Mon</span>
                            </h6>
                          </div>
                          <div className="single-data">
                            <p>HUI</p>
                            <span>Special</span>
                            <h6>
                              224.658 <span>Year</span>
                            </h6>
                          </div>
                        </div> */}
                        <a
                          href="https://prempackaging.com/"
                          style={{ color: "#E92227" }}
                          target="_blank"
                          rel="noreferrer"
                          // onClick={ScrollToTop}
                        >
                          Read More
                          {/* <i className="fas fa-long-arrow-right"></i> */}
                          <i className="fal fa-plus"></i>
                        </a>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="tab-img">
                          <img src={PackagingSectorImg} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-tab-2"
                  role="tabpanel"
                  aria-labelledby="pills-tab-2"
                >
                  <div className="tab-content-wrapper">
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <h2>
                          Forging strength and precision into every steel
                          product.
                        </h2>
                        <p style={{ textAlign: "justify" }}>
                          As a prominent player in the steel industry, we are
                          dedicated to delivering top-quality products and
                          driving innovation. Our associated companies PH Steel
                          Industries, Mangaldeep Steels, PH Steels, PremHari
                          Steels Private Ltd, and Dream Metplast Private
                          Limited. are known for setting industry benchmarks for
                          excellence.
                        </p>
                        {/* <div className="data-flex">
                          <div className="single-data">
                            <p>POF</p>
                            <span>Elemental</span>
                            <h6>
                              24.658 <span>Mon</span>
                            </h6>
                          </div>
                          <div className="single-data">
                            <p>HUI</p>
                            <span>Special</span>
                            <h6>
                              224.658 <span>Year</span>
                            </h6>
                          </div>
                        </div> */}
                        <a
                          href="https://www.phsteel.in/"
                          style={{ color: "#E92227" }}
                          // onClick={ScrollToTop}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Read More
                          {/* <i className="fas fa-long-arrow-right"></i> */}
                          <i className="fal fa-plus"></i>
                        </a>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="tab-img">
                          <img src={SteelSectorImg} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-tab-3"
                  role="tabpanel"
                  aria-labelledby="pills-tab-2"
                >
                  <div className="tab-content-wrapper">
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <h2>
                          Delivering quality and trust across diverse consumer
                          needs.
                        </h2>
                        <p style={{ textAlign: "justify" }}>
                          We lead the retail sector by offering top-quality
                          products through our associated companies companies
                          Prem Paints, Nourishing Foods Pvt. Ltd., Prem Shanti
                          and Company, Mangaldeep, and Prem Pigments Limited.
                          Our focus on excellence and innovation ensures that we
                          remain a trusted name in the industry.
                        </p>
                        {/* <div className="data-flex">
                          <div className="single-data">
                            <p>POF</p>
                            <span>Elemental</span>
                            <h6>
                              24.658 <span>Mon</span>
                            </h6>
                          </div>
                          <div className="single-data">
                            <p>HUI</p>
                            <span>Special</span>
                            <h6>
                              224.658 <span>Year</span>
                            </h6>
                          </div>
                        </div> */}
                        <Link
                          to="/projectDetails"
                          style={{ color: "#E92227" }}
                          onClick={ScrollToTop}
                        >
                          Read More
                          {/* <i className="fas fa-long-arrow-right"></i> */}
                          <i className="fal fa-plus"></i>
                        </Link>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="tab-img">
                          <img src={RetailSectorImg} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-tab-4"
                  role="tabpanel"
                  aria-labelledby="pills-tab-2"
                >
                  <div className="tab-content-wrapper">
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <h2>
                          Building landmarks with innovation and integrity.
                        </h2>
                        <p style={{ textAlign: "justify" }}>
                          Through our expertise in the construction sector, we
                          have successfully completed a wide range of projects,
                          including malls, multiplexes, institutions, and
                          industrial sheds. Our commitment to quality and timely
                          delivery makes us a reliable partner in building
                          robust and innovative structures.
                        </p>
                        {/* <div className="data-flex">
                          <div className="single-data">
                            <p>POF</p>
                            <span>Elemental</span>
                            <h6>
                              24.658 <span>Mon</span>
                            </h6>
                          </div>
                          <div className="single-data">
                            <p>HUI</p>
                            <span>Special</span>
                            <h6>
                              224.658 <span>Year</span>
                            </h6>
                          </div>
                        </div> */}
                        <a
                          href="project-details.html"
                          style={{ color: "#E92227" }}
                          onClick={ScrollToTop}
                        >
                          Read More
                          {/* <i className="fas fa-long-arrow-right"></i> */}
                          <i className="fal fa-plus"></i>
                        </a>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="tab-img">
                          <img src={ConstructorSectorImg} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectsTab;
