import React from "react";
import Logo from "./Images/logo.png";

const Preloader = () => {
  return (
    <>
      <div id="preloader" className="preloader">
        <div className="animation-preloader">
          <img src={Logo} alt="" className="text-center mb-4" />
          <div className="txt-loading">
            <span data-text-preloader="" className="letters-loading">
              P
            </span>
            <span data-text-preloader="" className="letters-loading">
              R
            </span>
            <span data-text-preloader="" className="letters-loading">
              E
            </span>
            <span data-text-preloader="" className="letters-loading">
              M
            </span>
            <span data-text-preloader=" " className="letters-loading">
              {" "}
            </span>
            <span data-text-preloader="" className="letters-loading">
              I
            </span>
            <span data-text-preloader="" className="letters-loading">
              N
            </span>
            <span data-text-preloader="" className="letters-loading">
              D
            </span>
            <span data-text-preloader="" className="letters-loading">
              U
            </span>
            <span data-text-preloader="" className="letters-loading">
              S
            </span>
            <span data-text-preloader="" className="letters-loading">
              T
            </span>
            <span data-text-preloader="" className="letters-loading">
              R
            </span>
            <span data-text-preloader="" className="letters-loading">
              I
            </span>
            <span data-text-preloader="" className="letters-loading">
              E
            </span>
            <span data-text-preloader="" className="letters-loading">
              S
            </span>
          </div>
          <p className="text-center mt-5">
            Loading Content... <br />
            {/* <div className="spinner"></div> */}
          </p>
        </div>
        <div className="loader">
          <div className="row">
            <div className="col-3 loader-section section-left">
              <div className="bg"></div>
            </div>
            <div className="col-3 loader-section section-left">
              <div className="bg"></div>
            </div>
            <div className="col-3 loader-section section-right">
              <div className="bg"></div>
            </div>
            <div className="col-3 loader-section section-right">
              <div className="bg"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preloader;
