import React from "react";
// import img1 from "../../assets/img/why-bg.jpg";
import InnovationImg from "../Images/Innovation.jpg";

const WhyUs = () => {
  return (
    <section className="why-choice-us section-padding why-choose-us-margin-top mb-5 wow fadeInLeft animated">
      <div
        className="why-choice-bg bg-cover d-none d-xl-block"
        style={{ backgroundImage: `url(${InnovationImg})` }}
      ></div>
      <div className="container">
        <div className="row">
          <div className="offset-xl-6 offset-lg-4 col-lg-8 col-xl-6 col-12">
            <div className="block-contents">
              <div className="section-title">
                {/* <span>Why Choose Us</span> */}
                <h2>Our Innovation</h2>
              </div>
            </div>
            <div className="single-icon-circle-item">
              {/* <div className="icon">
                <i className="flaticon-cpu"></i>
              </div> */}
              <div className="contents">
                <h3>Integrated Sustainable Practices</h3>
                <p style={{ textAlign: "justify" }}>
                  Our company leads the way in sustainability by embedding
                  eco-friendly practices across all sectors.
                </p>
              </div>
            </div>
            <div className="single-icon-circle-item">
              {/* <div className="icon">
                <i className="flaticon-secure-shield"></i>
              </div> */}
              <div className="contents">
                <h3>Cross-Sector Technological Synergy</h3>
                <p style={{ textAlign: "justify" }}>
                  We harness cutting-edge technology across diverse sectors to
                  create synergies that drive innovation and efficiency.
                </p>
              </div>
            </div>
            <div className="single-icon-circle-item">
              {/* <div className="icon">
                <i className="flaticon-secure-shield"></i>
              </div> */}
              <div className="contents">
                <h3>Customer-Centric Product Development</h3>
                <p style={{ textAlign: "justify" }}>
                  We consistently develop new products and services tailored to
                  evolving market demands, whether it’s through smart packaging
                  solutions, high-performance steel, personalized retail
                  experiences, or state of the art construction techniques.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
