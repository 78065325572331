import React from "react";
import { Link } from "react-router-dom";

const MobileMenu = ({ mobileMenu, handleMobileMenu }) => {
  return (
    <div className={mobileMenu ? "mobile-nav" : "mobile-nav show"}>
      <button onClick={handleMobileMenu} type="button" className="close-nav">
        <i className="fal fa-times-circle"></i>
      </button>
      <nav className="sidebar-nav">
        <ul className="metismenu" id="mobile-menu">
          <li>
            <a className="has-arrow" href="#">
              HOME
            </a>
          </li>
          <li>
            <Link to="/about">ABOUT US</Link>
          </li>
          <li>
            <Link to="/services">SECTORS</Link>
            <ul className="sub-menu">
              <li>
                <a
                  href="https://prempackaging.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  PACKAGING SECTOR
                </a>
              </li>
              <li>
                <a href="https://phsteel.in/" target="_blank" rel="noreferrer">
                  STEEL SECTOR
                </a>
              </li>
              <li>
                <Link to="/">RETAIL SECTOR</Link>
              </li>
              <li>
                <Link to="/">CONSTRUCTION SECTOR</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link className="has-arrow" to="/clients">
              OUR CLIENTS
            </Link>
          </li>
          <li>
            <Link to="/contact">CONTACT US</Link>
          </li>
          <li>
            <Link to="/">OTHER WEBSITES</Link>
            <ul className="sub-menu">
              <li>
                <a
                  href="https://prempackaging.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  PREM PACKAGING
                </a>
              </li>
              <li>
                <a href="https://phsteel.in/" target="_blank" rel="noreferrer">
                  PH STEEL
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>

      <div className="action-bar">
        <a href="mailto:info@example.com">
          <i className="fal fa-envelope"></i>info@premindustries.in
        </a>
        <a href="tel:123-456-7890">
          <i className="fal fa-phone"></i>+91-84-472-47227
        </a>
        <Link to="/contact" className="d-btn theme-btn">
          Contact Us
        </Link>
      </div>
    </div>
  );
};

export default MobileMenu;
