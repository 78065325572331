import React from "react";
// import bannerBg from "../assets/img/page-banner.jpg";
import BannerImg from "../Components/Images/PrivacyPolicyImg.jpg";
import PageBanner from "../Components/PageBanner";
import { Helmet } from "react-helmet";

export default function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Prem Industries India Limited</title>
        <meta name="description" content="Privacy Policy" />
      </Helmet>
      <PageBanner
        title="Privacy Policy"
        bannerBg={BannerImg}
        currentPage="About"
      />
      <div className="container" style={{ textAlign: "justify" }}>
        <div className="row">
          <div className="col-md-12 mt-3">
            {/* <h2>Heading</h2> */}
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              Prem Industries India Limited is committed to protecting the
              privacy and confidentiality of the personal information we collect
              from our customers, clients, employees, and business partners.
              This Privacy Policy outlines how we handle, store, and process
              personal information across our four key sectors: Packaging,
              Retail, Steel, and Construction & Infrastructure.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>Information We Collect</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              We may collect personal information such as name, contact details,
              email addresses, job titles, and other relevant data when you
              interact with us through our website, customer service, or
              business transactions. For employees, we may also collect
              additional information, such as identification numbers, financial
              details, and employment history.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>How We Use Your Information</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              The personal information we collect is used for various purposes,
              including:
            </p>
            <ol style={{ fontSize: "17px" }}>
              <li>
                <b>1. Packaging Sector:</b> To process orders, manage customer
                relationships, and improve our products and services.
              </li>
              <li>
                <b>2. Retail Sector:</b> To manage sales transactions, enhance
                customer service, and tailor marketing efforts to your
                preferences.
              </li>
              <li>
                <b>3. Steel Sector:</b> To facilitate business operations,
                ensure the timely delivery of products, and maintain robust
                supply chain management.
              </li>
              <li>
                <b>4. Construction & Infrastructure Sector:</b> To manage
                projects, communicate with stakeholders, and ensure compliance
                with regulatory requirements.
              </li>
            </ol>
            <p>
              In addition, we may use your information for internal
              record-keeping, compliance with legal obligations, and to protect
              our rights and interests.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>Data Security</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              We implement stringent security measures to protect your personal
              information from unauthorized access, disclosure, alteration, or
              destruction. Our systems are regularly updated and monitored to
              ensure data integrity and confidentiality.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>Data Sharing and Disclosure</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              We do not sell, trade, or rent your personal information to third
              parties. However, we may share your information with trusted
              partners and service providers who assist us in operating our
              business, provided they agree to keep your information
              confidential. We may also disclose your information when required
              by law or to protect our legal rights.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>Your Rights</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              You have the right to access, correct, or delete your personal
              information held by us. If you wish to exercise these rights or
              have any concerns about how we handle your data, please contact us
              at +918447247227
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3 mb-4">
            <h2>Changes to This Policy</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or legal requirements. Any changes will
              be posted on our website, and we encourage you to review this
              policy periodically.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
